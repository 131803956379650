import React from 'react'

import Slider from 'react-slick'

import { ProductItem } from './catalog-products'
import ProductItemCard from './catalog-products/product-item-card'
import { NextArrow, PreviousArrow } from '../intro/shared'

const SectionRelatedProducts = ({
  relatedProducts,
  productType,
}: {
  productType?: 'fronts' | 'handles' | 'features'
  relatedProducts?: ProductItem[]
}) => {
  const settingsProductsSlider = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: false,
    focusOnSelect: false,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    className: 'related-products-slider',

    responsive: [
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    <div tw="grid grid-cols-1 w-full gap-x-8 gap-y-8 sm:(grid-cols-2) md:(grid-cols-4)">
      <Slider
        tw="col-span-2 py-2 text-center md:(col-span-4 py-4)"
        {...settingsProductsSlider}
      >
        {relatedProducts?.map((item, i) => {
          return (
            <ProductItemCard
              key={`product-${i}`}
              item={item}
              product={productType}
            />
          )
        })}
      </Slider>
    </div>
  )
}

export { SectionRelatedProducts }
