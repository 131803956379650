import React from 'react'

import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons'
import Slider from 'react-slick'
import tw from 'twin.macro'

import { ModLink } from '../../components'

const disabled = tw`cursor-not-allowed opacity-50`

function NextArrow(props: { onClick?: any }) {
  const { onClick } = props
  return (
    <div
      tw="absolute cursor-pointer w-8 h-8 bg-white flex items-center justify-center rounded-full shadow-lg border border-white text-gray-900 z-10 right-2 xxxs:(-top-12) xxs:(-top-20) md:(-top-16 right-6)"
      onClick={onClick}
      css={[!onClick ? disabled : '']}
    >
      <ArrowRightIcon color="currentColor" width={20} height={20} />
    </div>
  )
}

function PrevArrow(props: { onClick?: any }) {
  const { onClick } = props
  return (
    <div
      tw="absolute cursor-pointer w-8 h-8 bg-white flex items-center justify-center rounded-full shadow-lg border border-white text-gray-900 z-10 right-15 xxxs:(-top-12) xxs:(-top-20) md:(-top-16 right-16)"
      onClick={onClick}
      css={[!onClick ? disabled : '']}
    >
      <ArrowLeftIcon color="currentColor" height={20} width={20} />
    </div>
  )
}

const CxSlider = ({
  children,
  link,
  linkText,
  settingsSliderNavOverrides,
  title,
}: {
  children: any
  link?: string
  linkText?: string
  settingsSliderNavOverrides?: any
  title: string
}) => {
  const settingsSliderNav = {
    appendDots: (dots: any) => (
      <ul tw="!bottom-[-2rem]">
        <span className="default">{dots}</span>
      </ul>
    ),
    arrows: false,
    dots: false,
    focusOnSelect: true,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 4,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    ...settingsSliderNavOverrides,
  }

  return (
    <React.Fragment>
      <div tw="flex items-center justify-between px-4 border-b border-dashed pb-2 md:(pr-6 pl-4) xl:(pl-0)">
        <div tw="mb-8 md:(mb-4)">
          <p tw="text-4xl font-thin">{title}</p>
          {link ? (
            <ModLink
              to={link}
              css={[
                tw`text-base space-x-2 inline-flex items-center font-medium text-gray-600 visited:text-gray-600`,
              ]}
              caps="true"
            >
              {linkText ? (
                <span tw="text-gray-600 visited:text-gray-600">{linkText}</span>
              ) : (
                <span tw="text-gray-600 visited:text-gray-600">
                  View all {title}
                </span>
              )}
              <ArrowRightIcon tw="text-gray-700 top-0.5 relative" />
            </ModLink>
          ) : null}
        </div>
      </div>
      <Slider {...settingsSliderNav}>{children}</Slider>
    </React.Fragment>
  )
}

export default CxSlider
