import React, { useEffect } from 'react'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import { Section } from '../../section'
import CxSlider from '../intro/slider'

const SectionGallery = ({ galleryItems }: { galleryItems?: any }) => {
  const [galleryItemsConfigured, setGalleryItemsConfigured] = React.useState<
    {
      image?: any
      title?: string
    }[]
  >([])

  useEffect(() => {
    if (galleryItems && galleryItems.length >= 1) {
      const slides: {
        image?: any
        title?: string
      }[] = []

      galleryItems.forEach(
        (galleryItem: {
          title: string
          localFile?: {
            childImageSharp?: {
              gatsbyImageData?: IGatsbyImageData
            }
          }
        }) => {
          slides.push({
            image: galleryItem.localFile,
            title: galleryItem.title,
          })
        },
      )
      setGalleryItemsConfigured(slides)
    }
  }, [galleryItems])

  return (
    <>
      {galleryItemsConfigured?.length >= 1 && (
        <Section padding="top">
          <CxSlider
            title="Product Gallery"
            settingsSliderNavOverrides={{
              slidesToShow: 2,
              arrows: true,
              className: 'product-gallery-slider',
              responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    dots: true,
                  },
                },
              ],
            }}
          >
            {galleryItemsConfigured.map((render, idx: number) => {
              return (
                <div key={idx}>
                  <div tw="sm:(p-4)">
                    <GatsbyImage
                      alt={render.title ?? ''}
                      image={
                        render?.image?.childImageSharp
                          ?.gatsbyImageData as IGatsbyImageData
                      }
                      css={tw`max-h-[235px] md:max-h-[400px]`}
                      imgStyle={tw`object-contain max-w-full`}
                    />
                  </div>
                </div>
              )
            })}
          </CxSlider>
        </Section>
      )}
    </>
  )
}

export { SectionGallery }
