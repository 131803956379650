import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import fireEvent from 'context/tracking/events'
import { Breadcrumbs } from 'mx/cx/category/breadcrumbs'
import { SectionGallery } from 'mx/cx/category/section-gallery'
import { SectionRelatedProducts } from 'mx/cx/category/section-related-products'
import { HeroFront } from 'mx/cx/fronts/hero'
import { HeroHandle } from 'mx/cx/handles/hero'
import Layout from 'mx/layout'
import { Section } from 'mx/section'
import { camelCaseToTitleCase, scrollToTop } from 'views/utils'

const ProductItem = ({
  location,
  pageContext,
}: {
  location: Location
  pageContext: any
}) => {
  const {
    description,
    drillPatterns,
    finish,
    height,
    imageBlankFront,
    imageBlankSide,
    imageHandleless,
    imageHandlelessSide,
    imageMain,
    imageMainClose,
    material,
    optionKey,
    photoGallery,
    priceRange,
    productType,
    relatedFronts,
    relatedHandles,
    rotatingVideo,
    texture,
  } = pageContext

  const relatedProducts =
    productType === 'fronts' ? relatedFronts : relatedHandles

  useEffect(() => {
    // setTimeout makes sure we don't preserve the scroll position from the previous page
    setTimeout(() => scrollToTop(), 0)
    fireEvent({
      type: 'page_viewed',
      pageTitle: `FORM Catalog - ${camelCaseToTitleCase(
        productType,
      )} - ${optionKey}`,
    })
  }, [])

  return (
    <div tw="relative">
      <Layout>
        <Breadcrumbs
          base={{ link: '/catalog', title: 'Our Catalog' }}
          crumbs={[
            { title: productType, link: `/catalog/${productType}` },
            { title: optionKey, link: `/catalog/${productType}/${optionKey}` },
          ]}
        />
        {productType === 'fronts' ? (
          <HeroFront
            description={description}
            drillPatterns={drillPatterns}
            finish={finish}
            handle={relatedHandles}
            imageBlankFront={imageBlankFront}
            imageBlankSide={imageBlankSide}
            imageHandleless={imageHandleless}
            imageHandlelessSide={imageHandlelessSide}
            imageMain={imageMain}
            imageMainClose={imageMainClose}
            location={location}
            material={material}
            optionKey={optionKey}
            priceRange={priceRange}
            relatedHandles={relatedHandles}
            rotatingVideo={rotatingVideo}
            texture={texture}
          />
        ) : (
          <HeroHandle
            description={description}
            drillPatterns={drillPatterns}
            height={height}
            imageMain={imageMain}
            imageMainClose={imageMainClose}
            optionKey={optionKey}
            priceRange={priceRange}
          />
        )}

        {photoGallery && <SectionGallery galleryItems={photoGallery} />}
        {relatedProducts && (
          <Section padding="top">
            <div tw="px-4 md:px-6 space-y-16">
              <p tw="text-gray-800 text-4xl leading-normal font-thin">
                Other products you might like
              </p>
              <SectionRelatedProducts
                productType={productType}
                relatedProducts={relatedProducts}
              />
            </div>
          </Section>
        )}
      </Layout>
    </div>
  )
}

export default ProductItem
export const Head = ({ pageContext }: { pageContext: any }) => {
  const { description, imageMain, optionKey, productType } = pageContext
  const path = `/catalog/${productType}/${optionKey
    .toLocaleLowerCase()
    .replace(/ /g, '-')}`
  const metaDescription =
    productType === 'fronts'
      ? `See our modern cabinetry like our ${description} in the brand new FORM catalog`
      : `See our modern kitchen finishes like the ${description} in the brand new FORM catalog`

  return (
    <SEO
      description={metaDescription}
      image={
        imageMain.localFile?.childImageSharp?.gatsbyImageData?.images.fallback
          .src
      }
      path={path}
      title={`Catalog - ${camelCaseToTitleCase(productType)} - ${optionKey}`}
    />
  )
}
