import React from 'react'

import { ChevronRightIcon } from '@radix-ui/react-icons'
import { Link } from 'gatsby'
import 'twin.macro'

interface BreadcrumbsProps {
  base: {
    title: string
    link: string
  }
  crumbs: {
    title: string
    link?: string
  }[]
}

const Breadcrumbs = ({ base, crumbs }: BreadcrumbsProps) => {
  return (
    <nav
      tw="px-4 py-4 flex border-b border-gray-100 md:(px-10)"
      aria-label="Breadcrumb"
    >
      <ol tw="flex items-center space-x-2">
        <li tw="text-sm capitalize text-gray-800">
          <Link to={base.link}>{base.title}</Link>
        </li>
        {crumbs.map((crumb, index) => {
          if (crumb.link) {
            return (
              <li key={index} tw="flex items-center">
                <ChevronRightIcon />

                <Link to={crumb.link} tw="ml-2">
                  <p tw="capitalize text-sm text-gray-800">{crumb.title}</p>
                </Link>
              </li>
            )
          }
          return (
            <li key={index} tw="flex items-center">
              <ChevronRightIcon />

              <p tw="ml-2 capitalize text-sm text-gray-800">{crumb.title}</p>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export { Breadcrumbs }
