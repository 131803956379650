import React, { ReactNode } from 'react'

import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import tw from 'twin.macro'

import { ProductItem } from '.'

type ProductCardProps = {
  item: ProductItem
  mobileSlider?: boolean
  product?: 'fronts' | 'handles' | 'features'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const settingsFrontItemSlider = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  infinite: false,
  focusOnSelect: false,
  touchThreshold: 20,
  className: 'catalog-item-slider',
  appendDots: (dots: any) => {
    return <ul tw="!relative !bottom-0">{dots}</ul>
  },
}

const handleSwipe = (e: any) => {
  if (e.target != e.currentTarget) e.stopPropagation()
}

const CardContainer: React.FC<{ children?: ReactNode | undefined }> = ({
  children,
}) => {
  return (
    <div tw="relative p-2 sm:(pt-10 pb-6 px-6) lg:(p-10)">
      <div tw="mx-10 md:mx-0">{children}</div>
    </div>
  )
}

const ProductItemCard = ({
  item,
  mobileSlider,
  product,
  onClick,
}: ProductCardProps) => {
  return product !== 'features' ? (
    <Link
      tw="w-full p-4 md:(hover:shadow-md rounded-md p-0) h-[max-content]"
      to={`/catalog/${product}/${item.optionKey}`}
      key={item.optionKey}
    >
      <CardContainer>
        <div
          tw="relative block"
          css={mobileSlider ? tw`!hidden md:(!block)` : undefined}
        >
          <div
            css={[
              product === 'fronts' &&
                item.imageMainClose &&
                tw`md:(z-10 opacity-100 hover:opacity-0 z-0 transition-all)`,
            ]}
          >
            {item.imageMain ? (
              <GatsbyImage
                alt={`Front ${item.optionKey}`}
                image={
                  item.imageMain?.localFile?.childImageSharp
                    ?.gatsbyImageData as IGatsbyImageData
                }
              />
            ) : null}
          </div>
          {product === 'fronts' && item.imageMainClose ? (
            <div tw="absolute transform -translate-x-2/4 -translate-y-2/4 top-2/4 left-2/4 w-full max-h-full z-10 opacity-0 md:(hover:opacity-100 transform transition-all)">
              <GatsbyImage
                tw="static"
                alt={`Front ${item.optionKey}`}
                image={
                  item.imageMainClose?.localFile?.childImageSharp
                    ?.gatsbyImageData as IGatsbyImageData
                }
              />
            </div>
          ) : null}
        </div>
        {mobileSlider && (
          <div tw="block md:(!hidden)" onTouchMove={handleSwipe}>
            <Slider {...settingsFrontItemSlider}>
              <div>
                {item.imageMain ? (
                  <GatsbyImage
                    alt={`Front ${item.optionKey}`}
                    tw=""
                    image={
                      item.imageMain?.localFile?.childImageSharp
                        ?.gatsbyImageData as IGatsbyImageData
                    }
                  />
                ) : null}
              </div>
              {product === 'fronts' && item.imageMainClose ? (
                <div>
                  <GatsbyImage
                    alt={`Front ${item.optionKey}`}
                    image={
                      item.imageMainClose?.localFile?.childImageSharp
                        ?.gatsbyImageData as IGatsbyImageData
                    }
                  />
                </div>
              ) : null}
            </Slider>
          </div>
        )}
        <div tw="space-y-0.5 text-left">
          <div tw="flex items-center space-x-3 pt-4">
            <p tw="text-black font-sans font-normal">{item?.optionKey}</p>
          </div>
          <p tw="text-base text-gray-600 font-light">{item?.description}</p>
          <p tw="text-base text-gray-600 font-light">
            Price Range: <span tw="text-gray-700">{item?.priceRange}</span>
          </p>
        </div>
      </CardContainer>
    </Link>
  ) : (
    <button
      tw="w-full p-4 md:(hover:shadow-md rounded-md p-0) h-[max-content]"
      onClick={onClick}
    >
      <CardContainer>
        <div tw="relative hidden md:(block)">
          <div>
            {item.imageMain ? (
              <GatsbyImage
                alt={`Feature ${item.description}`}
                tw=""
                image={
                  item.imageMain?.localFile?.childImageSharp
                    ?.gatsbyImageData as IGatsbyImageData
                }
              />
            ) : null}
          </div>
        </div>
        <div tw="block md:(!hidden)" onTouchMove={handleSwipe}>
          <Slider {...settingsFrontItemSlider}>
            <div>
              {item.imageMain ? (
                <GatsbyImage
                  alt={`Feature ${item.description}`}
                  tw=""
                  image={
                    item.imageMain?.localFile?.childImageSharp
                      ?.gatsbyImageData as IGatsbyImageData
                  }
                />
              ) : null}
            </div>
          </Slider>
        </div>
        <div tw="space-y-0.5 text-left">
          <div tw="flex items-center space-x-3 pt-4">
            <p tw="text-black font-sans font-normal">{item?.description}</p>
          </div>
          <p tw="text-base text-gray-600 font-light">
            Price Range: <span tw="text-gray-700">{item?.priceRange}</span>
          </p>
        </div>
      </CardContainer>
    </button>
  )
}

export default ProductItemCard
