import React, { useEffect, useState, useRef, LegacyRef } from 'react'

import 'twin.macro'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider from 'react-slick'

import { scrollToTop } from 'views/utils'

interface ProductSliderProps {
  handleOverlay?: {
    [x: number]: {
      title?: string
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData
        }
      }
    }[]
  }
  imagesBlank?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }[]
  imagesHandleless?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }[]
  imageMain?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  imageMainClose?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  isConfiguring?: boolean
  isHandleless?: boolean
  rotatingVideo?: any
  selectedHandleStyle?: any
}

const ProductSlider = ({
  handleOverlay,
  imageMain,
  imageMainClose,
  imagesBlank,
  imagesHandleless,
  isConfiguring,
  isHandleless,
  rotatingVideo,
  selectedHandleStyle,
}: ProductSliderProps) => {
  const [galleryLength, setGalleryLength] = useState(0)
  const sliderMain = useRef<Slider>()
  const sliderThumbs = useRef<Slider>()

  useEffect(() => {
    let length = 0
    length += imageMain ? 1 : 0
    length += imageMainClose ? 1 : 0
    length += rotatingVideo ? 1 : 0
    length += imagesBlank || imagesHandleless ? 2 : 0
    setGalleryLength(length)
  }, [isConfiguring, selectedHandleStyle])

  useEffect(() => {
    if (isConfiguring) {
      const sliderIndex = galleryLength - 2
      scrollToTop()
      sliderMain.current?.slickGoTo(sliderIndex)
      sliderThumbs.current?.slickGoTo(sliderIndex)
    }
  })

  const settingsMain = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    swipe: true,
    asNavFor: sliderThumbs.current,
  }
  const settingsThumbs = {
    slidesToShow: 5,
    infinite: false,
    asNavFor: sliderMain.current,
    focusOnSelect: true,
    className: 'catalog-category-hero-slider-thumbs-dots',
  }
  return (
    <>
      <Slider
        {...settingsMain}
        className="catalog-category-hero-slider"
        ref={sliderMain as LegacyRef<Slider>}
      >
        {imageMain ? (
          <div key={imageMain.title} tw="flex justify-center h-full py-8">
            <div tw="max-w-xs mx-20 sm:(mx-auto)">
              <GatsbyImage
                alt={imageMain.title ?? ''}
                image={
                  imageMain?.localFile?.childImageSharp
                    ?.gatsbyImageData as IGatsbyImageData
                }
              />
            </div>
          </div>
        ) : null}
        {imageMainClose ? (
          <div key={imageMainClose.title} tw="flex justify-center h-full py-8">
            <div tw="max-w-xs mx-20 sm:(mx-auto)">
              <GatsbyImage
                alt={imageMainClose.title ?? ''}
                image={
                  imageMainClose?.localFile?.childImageSharp
                    ?.gatsbyImageData as IGatsbyImageData
                }
              />
            </div>
          </div>
        ) : null}
        {rotatingVideo ? (
          <div
            key={rotatingVideo.title}
            tw="flex h-80 justify-center sm:(h-128)"
          >
            <video controls tw="h-80 mx-auto md:(h-128)">
              <source src={rotatingVideo?.file.url} type="video/mp4" />
            </video>
          </div>
        ) : null}
        {imagesBlank && !isHandleless && handleOverlay?.[selectedHandleStyle]
          ? imagesBlank?.map((b, idx) => (
              <div
                key={`configurator-${idx}`}
                tw="flex justify-center py-8 h-full"
              >
                <div tw="max-w-xs relative mx-20 sm:(mx-auto)">
                  <GatsbyImage
                    tw="absolute left-0 inset-0 z-50"
                    alt={
                      handleOverlay?.[selectedHandleStyle]?.[idx]?.title ?? ''
                    }
                    image={
                      handleOverlay?.[selectedHandleStyle]?.[idx]?.localFile
                        ?.childImageSharp?.gatsbyImageData as IGatsbyImageData
                    }
                  />
                  <GatsbyImage
                    alt={b.title ?? ''}
                    image={
                      b?.localFile?.childImageSharp
                        ?.gatsbyImageData as IGatsbyImageData
                    }
                  />
                </div>
              </div>
            ))
          : null}
        {imagesHandleless && isHandleless
          ? imagesHandleless?.map((hl, idx) => (
              <div key={`configurator-${idx}`} tw="flex justify-center">
                <div tw="max-w-xs mx-20 sm:(mx-auto)">
                  <GatsbyImage
                    alt={hl.title ?? ''}
                    image={
                      hl?.localFile?.childImageSharp
                        ?.gatsbyImageData as IGatsbyImageData
                    }
                  />
                </div>
              </div>
            ))
          : null}
      </Slider>
      {galleryLength > 1 && (
        <div tw="pl-4 space-x-2 mt-4 w-full sm:(w-3/4)">
          <Slider {...settingsThumbs} ref={sliderThumbs as LegacyRef<Slider>}>
            {imageMain ? (
              <div key={imageMain.title} tw="cursor-pointer">
                <div
                  className="thumbnail"
                  tw="w-12 h-16 m-auto overflow-hidden"
                >
                  <GatsbyImage
                    alt={imageMain.title ?? ''}
                    image={
                      imageMain?.localFile?.childImageSharp
                        ?.gatsbyImageData as IGatsbyImageData
                    }
                  />
                </div>
              </div>
            ) : null}
            {imageMainClose ? (
              <div key={imageMainClose.title} tw="cursor-pointer">
                <div
                  className="thumbnail"
                  tw="flex items-center justify-center w-12 h-16 m-auto overflow-hidden"
                >
                  <GatsbyImage
                    alt={imageMainClose.title ?? ''}
                    image={
                      imageMainClose?.localFile?.childImageSharp
                        ?.gatsbyImageData as IGatsbyImageData
                    }
                  />
                </div>
              </div>
            ) : null}
            {rotatingVideo ? (
              <div
                key={rotatingVideo.title}
                className="thumbnail"
                tw="!w-12 !h-16 !flex items-center justify-center bg-gray-100 m-auto cursor-pointer text-gray-500"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 13.2l1.8 2.4c.18881.2518 0 .4312-.1.5l-2.6 1.5c-.17031.0953-.41844.0631-.5-.1-.1-.2-.1-.4.1-.5l1.6-.9C3.1 15.7 0 14.7 0 12.9c0-1.3 1.6-2.4 4.5-2.9.2 0 .4.1.4.3 0 .2-.1.4-.3.4-2.3.5-3.8 1.4-3.8 2.2 0 1 2.2 2.1 5.9 2.5l-1.3-1.8c-.1-.1-.1-.4.1-.5.1-.1.4-.1.5.1zM13.5999 9.90002c-.2 0-.4.09998-.4.29998s.1.4.3.4c2.3.5 3.8 1.4 3.8 2.2 0 1.2-3 2.5-7.5 2.6-.2 0-.4.2-.4.4s.2.4.4.4c4.1-.1 8.2-1.3 8.2-3.4 0-1.2-1.6-2.3-4.4-2.89998z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.6998 12.3h6.6c.2 0 .4-.2.4-.4V.4c0-.2-.2-.4-.4-.4h-6.6c-.2 0-.4.2-.4.4v11.5c0 .2.2.4.4.4zm.4-11.5h5.8v10.8h-5.8V.8z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            ) : null}
            {imagesBlank &&
            !isHandleless &&
            handleOverlay?.[selectedHandleStyle]
              ? imagesBlank?.map((b, idx) => (
                  <div key={`configurator-${idx}`} tw="cursor-pointer">
                    <div
                      className="thumbnail"
                      tw="w-12 h-16 m-auto overflow-hidden"
                    >
                      <GatsbyImage
                        tw="absolute w-12 z-50"
                        alt={
                          handleOverlay?.[selectedHandleStyle]?.[idx]?.title ??
                          ''
                        }
                        image={
                          handleOverlay?.[selectedHandleStyle]?.[idx]?.localFile
                            ?.childImageSharp
                            ?.gatsbyImageData as IGatsbyImageData
                        }
                      />
                      <GatsbyImage
                        alt={b.title ?? ''}
                        image={
                          b.localFile?.childImageSharp
                            ?.gatsbyImageData as IGatsbyImageData
                        }
                      />
                    </div>
                  </div>
                ))
              : null}
            {imagesHandleless && isHandleless
              ? imagesHandleless?.map((hl, idx) => (
                  <div key={`configurator-${idx}`} tw="cursor-pointer">
                    <div
                      className="thumbnail"
                      tw="w-12 h-16 m-auto overflow-hidden"
                    >
                      <GatsbyImage
                        alt={hl.title ?? ''}
                        image={
                          hl.localFile?.childImageSharp
                            ?.gatsbyImageData as IGatsbyImageData
                        }
                      />
                    </div>
                  </div>
                ))
              : null}
          </Slider>
        </div>
      )}
    </>
  )
}

export default ProductSlider
