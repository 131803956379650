import { useStaticQuery, graphql } from 'gatsby'

export const useHandleType = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulHandleType {
        data: nodes {
          id
          contentful_id
          title
          imageMain {
            file {
              url
            }
          }
          imageThumbnail {
            file {
              url
            }
          }
        }
      }
    }
  `)

  return content
}
