import React from 'react'

import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons'
import tw from 'twin.macro'

const disabled = tw`cursor-not-allowed opacity-50`

function NextArrow(props: { onClick?: any }) {
  const { onClick } = props
  return (
    <div
      tw="z-20 absolute right-4 top-1/2 -translate-y-1/2 w-8 h-8 cursor-pointer bg-white flex items-center justify-center rounded-full shadow-lg border border-white text-gray-800 md:(w-12 h-12)"
      onClick={onClick}
      css={[!onClick ? disabled : '']}
    >
      <ArrowRightIcon color="currentColor" width={20} height={20} />
    </div>
  )
}

function PreviousArrow(props: { onClick?: any }) {
  const { onClick } = props
  return (
    <div
      tw="z-20 absolute left-4 top-1/2 -translate-y-1/2 w-8 h-8 cursor-pointer flex items-center justify-center rounded-full bg-white shadow-lg border border-white text-gray-800 md:(w-12 h-12)"
      onClick={onClick}
      css={[!onClick ? disabled : '']}
    >
      <ArrowLeftIcon color="currentColor" height={20} width={20} />
    </div>
  )
}

const variants = {
  show: {
    opacity: 1,

    transition: {
      transition: {
        staggerChildren: 0.5,
      },
    },
  },
  hidden: { opacity: 0 },
}

const item = {
  show: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100, ease: 'easeOut' },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export { NextArrow, PreviousArrow, variants, item }
