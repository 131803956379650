import React, { Dispatch, SetStateAction, useEffect } from 'react'

import { ClassNames } from '@emotion/react'
import { Disclosure, Listbox, RadioGroup, Transition } from '@headlessui/react'
import {
  CheckIcon,
  ChevronDownIcon,
  MinusIcon,
  PlusIcon,
} from '@radix-ui/react-icons'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import { useHandleType } from 'content-queries/cx/handle-type'

const HandleConfigurator = ({
  handle,
  imagesHandleless,
  selectedHandleStyle,
  selectedHandleType,
  selectedHandlelessStyle,
  setSelectedHandleStyle,
  setSelectedHandleType,
  setSelectedHandlelessStyle,
  updateFrontInfo,
  defaultHandles,
  integratedHandles,
}: {
  handle: string[]
  imagesHandleless?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }[]
  defaultHandles?: any[]
  integratedHandles?: any[]
  selectedHandleStyle: number
  selectedHandlelessStyle: any
  selectedHandleType: string
  setSelectedHandleStyle: any
  setSelectedHandleType: Dispatch<
    SetStateAction<'handle' | 'integrated' | 'handleless'>
  >
  setSelectedHandlelessStyle: any
  updateFrontInfo: any
}) => {
  const { data: handleOptions } = useHandleType()
  if ((!defaultHandles && !integratedHandles) || handle === null) return null
  return (
    <>
      <RadioGroup
        tw="py-6"
        disabled={!defaultHandles && !integratedHandles}
        value={selectedHandleType}
        onChange={(x) =>
          updateFrontInfo('handleType', setSelectedHandleType, x)
        }
      >
        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button tw="w-full">
                <RadioGroup.Label tw="flex justify-between text-lg text-gray-700 leading-none">
                  <div tw="flex">
                    <p>Try out your favorite handle</p>
                  </div>
                  <div tw="items-center">
                    {open ? <MinusIcon /> : <PlusIcon />}
                  </div>
                </RadioGroup.Label>
              </Disclosure.Button>
              <ClassNames>
                {({ css }) => (
                  <Transition
                    show={open}
                    enter={css(tw`transition duration-100 ease-out`)}
                    enterFrom={css(tw`transform scale-95 opacity-0`)}
                    enterTo={css(tw`transform scale-100 opacity-100`)}
                    leave={css(tw`transition duration-75 ease-out`)}
                    leaveFrom={css(tw`transform scale-100 opacity-100`)}
                    leaveTo={css(tw`transform scale-95 opacity-0`)}
                  >
                    <Disclosure.Panel tw="grid grid-cols-3 gap-x-2 gap-y-2 py-8 md:p-4 lg:p-8">
                      <RadioGroup.Option
                        value="handle"
                        tw="cursor-pointer"
                        css={[!defaultHandles && tw`filter opacity-50`]}
                        disabled={!defaultHandles}
                      >
                        {({ checked }) => (
                          <div
                            tw="flex flex-col shrink-0 place-items-center text-center text-gray-600 cursor-pointer py-4 rounded hover:(ring-1 ring-gray-300)"
                            css={[
                              checked &&
                                tw`ring-1 ring-black hover:(ring-black)`,
                            ]}
                          >
                            <img
                              src={
                                handleOptions?.find(
                                  (o: any) => o.title === 'Panel with Handle',
                                )?.imageThumbnail?.file?.url
                              }
                            />
                            <p tw="text-base mt-2 font-light">Handle</p>
                          </div>
                        )}
                      </RadioGroup.Option>
                      <RadioGroup.Option
                        value="integrated"
                        tw="cursor-pointer"
                        css={[!integratedHandles && tw`filter opacity-50`]}
                        disabled={!integratedHandles}
                      >
                        {({ checked }) => (
                          <div
                            tw="flex flex-col shrink-0 place-items-center text-center text-gray-600 cursor-pointer py-4 rounded hover:(ring-1 ring-gray-300)"
                            css={[
                              checked &&
                                tw`ring-1 ring-black hover:(ring-black)`,
                            ]}
                          >
                            <img
                              src={
                                handleOptions?.find(
                                  (o: any) => o.title === 'Integrated Handle',
                                )?.imageThumbnail?.file?.url
                              }
                            />
                            <p tw="text-base mt-2 font-light">Integrated</p>
                          </div>
                        )}
                      </RadioGroup.Option>
                      <RadioGroup.Option
                        value="handleless"
                        tw="cursor-pointer"
                        css={[!imagesHandleless && tw`filter opacity-50`]}
                        disabled={!imagesHandleless}
                      >
                        {({ checked }) => (
                          <div
                            tw="flex flex-col shrink-0 place-items-center text-center text-gray-600 cursor-pointer py-4 rounded hover:(ring-1 ring-gray-300)"
                            css={[
                              checked &&
                                tw`ring-1 ring-black hover:(ring-black)`,
                            ]}
                          >
                            <img
                              src={
                                handleOptions?.find(
                                  (o: any) => o.title === 'Handle-less',
                                )?.imageThumbnail?.file?.url
                              }
                            />
                            <p tw="text-base mt-2 font-light">Handleless</p>
                          </div>
                        )}
                      </RadioGroup.Option>
                    </Disclosure.Panel>
                    <Disclosure.Panel>
                      <div tw="space-y-2">
                        {selectedHandleType === 'handle' && (
                          <HandleStyle
                            value={selectedHandleStyle}
                            options={defaultHandles ?? []}
                            setValue={(x) =>
                              updateFrontInfo(
                                'handleStyle',
                                setSelectedHandleStyle,
                                x,
                              )
                            }
                          />
                        )}
                        {selectedHandleType === 'integrated' && (
                          <HandleStyle
                            value={selectedHandleStyle}
                            options={integratedHandles ?? []}
                            setValue={(x) =>
                              updateFrontInfo(
                                'handleStyle',
                                setSelectedHandleStyle,
                                x,
                              )
                            }
                          />
                        )}
                        {selectedHandleType === 'handleless' && (
                          <HandlelessStyle
                            selectedHandlelessStyle={selectedHandlelessStyle}
                            setSelectedHandlelessStyle={
                              setSelectedHandlelessStyle
                            }
                          />
                        )}
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                )}
              </ClassNames>
            </>
          )}
        </Disclosure>
      </RadioGroup>
    </>
  )
}

const HandlelessStyle = ({
  selectedHandlelessStyle,
  setSelectedHandlelessStyle,
}: {
  selectedHandlelessStyle: string
  setSelectedHandlelessStyle: (x: string) => void
}) => {
  return (
    <Listbox
      value={selectedHandlelessStyle}
      onChange={setSelectedHandlelessStyle}
    >
      {({ open }) => (
        <>
          <div tw="relative w-full">
            <Listbox.Button tw="text-base w-full h-12 flex items-center justify-between px-4 py-1 bg-white rounded-sm border border-gray-200 cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-orange-500">
              <div tw="flex items-center">
                <p tw="text-lg text-gray-600">Handle style</p>
              </div>
              <div tw="flex items-center space-x-1">
                <p tw="text-lg text-gray-600 font-medium leading-none pr-2 md:(text-base)">
                  {selectedHandlelessStyle}
                </p>
                <ChevronDownIcon
                  tw="text-gray-600 text-lg"
                  aria-hidden="true"
                />
              </div>
            </Listbox.Button>
            <ClassNames>
              {({ css }) => (
                <Transition
                  show={open}
                  enter={css(tw`transition duration-100 ease-out`)}
                  enterFrom={css(tw`transform scale-95 opacity-0`)}
                  enterTo={css(tw`transform scale-100 opacity-100`)}
                  leave={css(tw`transition duration-75 ease-out`)}
                  leaveFrom={css(tw`transform scale-100 opacity-100`)}
                  leaveTo={css(tw`transform scale-95 opacity-0`)}
                >
                  <Listbox.Options
                    static={true}
                    tw="absolute bottom-0 w-full py-1 mt-1 overflow-auto text-lg bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:(text-base)"
                  >
                    <Listbox.Option value="horizontal">
                      {({ active }) => (
                        <div tw="flex items-center space-x-4 py-4 px-6">
                          <div
                            tw="w-12 h-14 border rounded-sm shrink-0"
                            css={[active ? tw`border-gray-800` : '']}
                          >
                            <div
                              tw="pt-0.5 w-full border-b"
                              css={[active ? tw`border-gray-800` : '']}
                            />
                          </div>
                          <div>
                            <p tw="text-lg text-gray-800 md:(text-base)">
                              Horizontal
                            </p>
                            <p tw="text-base text-gray-500 md:(text-sm)">
                              Located on top for base units and at the bottom
                              for wall units.
                            </p>
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  </Listbox.Options>
                </Transition>
              )}
            </ClassNames>
          </div>
        </>
      )}
    </Listbox>
  )
}

function HandleStyle({
  options,
  setValue,
  value,
}: {
  options: any[]
  setValue: React.Dispatch<React.SetStateAction<number>>
  value: number
}) {
  const [selectedOption, setSelectedOption] = React.useState<any>(null)

  useEffect(() => {
    let selected = options?.find((option) => {
      return value === option.optionKey
    })
    if (!selected) {
      selected = options?.[0]
    }
    setSelectedOption(selected)
  }, [value, options])
  return (
    <Listbox value={value} onChange={setValue}>
      {({ open }) => (
        <>
          <div tw="relative w-full">
            <Listbox.Button tw="text-base w-full h-12 flex items-center justify-between px-4 py-1 bg-white rounded-sm border border-gray-200 cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-orange-500">
              <div tw="flex items-center">
                <p tw="text-lg text-gray-600">Handle style</p>
              </div>
              {selectedOption?.imageMain ? (
                <span tw="flex items-center space-x-1 text-gray-400">
                  <div tw="w-16 flex items-center p-3">
                    <GatsbyImage
                      alt={`Handle style ${selectedOption?.optionKey}`}
                      tw=" "
                      image={
                        selectedOption?.imageMain?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                    />
                  </div>
                  <ChevronDownIcon
                    tw="text-gray-600 text-lg"
                    aria-hidden="true"
                  />
                </span>
              ) : null}
            </Listbox.Button>
            <ClassNames>
              {({ css }) => (
                <Transition
                  show={open}
                  enter={css(tw`transition duration-100 ease-out`)}
                  enterFrom={css(tw`transform scale-95 opacity-0`)}
                  enterTo={css(tw`transform scale-100 opacity-100`)}
                  leave={css(tw`transition duration-75 ease-out`)}
                  leaveFrom={css(tw`transform scale-100 opacity-100`)}
                  leaveTo={css(tw`transform scale-95 opacity-0`)}
                >
                  <Listbox.Options
                    static={true}
                    tw="absolute bottom-0 w-full py-1 mt-1 overflow-auto text-lg bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:(text-base)"
                  >
                    {options?.map((option, i) => (
                      <Listbox.Option key={i} value={option.optionKey}>
                        {({ active, selected }) => (
                          <div
                            tw="flex items-center space-x-4 py-1 px-2"
                            css={[
                              active
                                ? tw`bg-gray-500/5 text-gray-900`
                                : 'text-gray-700 bg-white',
                            ]}
                          >
                            <div tw="w-16 max-h-10 flex items-center p-3">
                              <GatsbyImage
                                alt={`Handle style ${option.optionKey}`}
                                image={
                                  option?.imageMain?.localFile.childImageSharp
                                    .gatsbyImageData
                                }
                              />
                            </div>
                            <div tw="flex-1 ">
                              <p tw="text-sm text-gray-800">
                                {option.category.title} - {option.optionKey}
                              </p>
                              <p tw="text-sm text-gray-600">
                                {option.description} - {option.color.title}
                              </p>
                            </div>
                            {selected && <CheckIcon />}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              )}
            </ClassNames>
          </div>
        </>
      )}
    </Listbox>
  )
}

export { HandleStyle, HandleConfigurator }
